

.ant-menu-dark .ant-menu-item > span > a {
    color: #fafafa;
}

.ant-menu-dark .ant-menu-item-selected > span :hover {
    color: #fff !important;
}

.ant-menu.ant-menu-dark {
    color: #fafafa;
}

.ant-menu-dark .ant-menu-item-selected > span {
    background-color: #f5ad00;
    border : 1px solid #f5ad00;
    border-radius: 12px;
    padding-left: 2px;
}
/* .nested-sub-menu > span{
    padding-left: 12px;
} */
.gx-app-sidebar .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow{
    left: 40px;
}

/* .ant-menu.ant-menu-dark  {
    padding-left: 6px;
} */

/* .ant-menu-dark .ant-menu-item > span > a {
    padding: #fafafa;
} */






