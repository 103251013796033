.login-heading-class{
  font-size: 25px;
  font-weight: 500;
  margin-left: 15px;
}

.login-button-class{
  font-size: 28px;
}


.ant-btn-round{
    height: auto;
    border-radius: 36px;
    padding: 10px 25px 10px 25px;
}

.gx-app-login-content .ant-input {
  background-color: #ffffff;
}
