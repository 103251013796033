.fixed-height{
    height: 50px !important;
    width: 50px !important;
    object-fit: cover;
    margin: 5px 5px;
}

.gx-user-popover{
    text-align: center;
}

.gx-user-popover li:nth-child(1){
    font-weight: 500;
    font-size: medium;
}